import supabase from "../configs/Supabase";
import { displayError } from "../helpers/CommonFunctions";
import { certificateDataType, deleteJobDataObjectType, jobDataReturnType, jobDataReturnTypeNested, jobDataType, jobDataTypeNested, subJobType } from "../types/dataFetcherTypes/JobsDataFetcherTypes";

export const createJob = async ( dataToUpdate:jobDataType ): Promise<jobDataReturnType> => {

    let isError = false

    const { data, error } = await supabase.from( 'jobs' ).insert(dataToUpdate, {
        returning: "minimal"
    })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as jobDataType[] | null,
        queryError: error
    }
}

export const fetchSingleJob = async (jobId:string) => {
    const { data, error } = await supabase.from('jobs')
                                            .select(`
                                                id,
                                                organization_id,
                                                event_id,
                                                templates:template_id (
                                                    id,
                                                    name
                                                ),
                                                certificate_type,
                                                user_id
                                            `)
                                            .eq('id', jobId)
                                            .limit(1)
                                            .single()
    if (error) {
        displayError('Something Broke', 'An error occurred. please try again later')
    }
    return data as {
        id: string,
        organization_id: string,
        event_id: string,
        templates: {
            id: string,
            name: string,
        },
        certificate_type: string,
        user_id: string,
    }
}

export const fetchJobs = async ( page:number=1, size:number=10, sortOnColumn:string='updated_at', sortOrder:string='ascending', serchText?:string|null, searchOnColumn?:string|null, user_id?:string ): Promise<jobDataReturnTypeNested> => {
    let isError = false
    let queryCondition = supabase
                            .from( 'jobs' )
                            .select( `
                                id,
                                name,
                                organizations:organization_id (
                                    organization_id,
                                    name
                                ),
                                events:event_id (
                                    id,
                                    name
                                ),
                                templates:template_id (
                                    id,
                                    name
                                ),
                                certificate_type,
                                certificates:certificates!certificates_job_id_fkey (
                                    id
                                ),
                                is_custom_email_template_set,
                                created_at,
                                updated_at
                            `, {
                                count: "exact"
                            })
                            .eq('user_id', user_id)
                            .eq('is_deleted', false)

    if (serchText && searchOnColumn) {
        let modifiedSearchOnColumn = searchOnColumn
        switch (searchOnColumn) {
            case 'event_name':
                modifiedSearchOnColumn = 'events.name'
                break;

            case 'organization_name':
                modifiedSearchOnColumn = 'organizations.name'
                break;

            case 'template_name':
                modifiedSearchOnColumn = 'templates.name'
                break;
        
            default:
                modifiedSearchOnColumn = searchOnColumn
                break;
        }
        queryCondition = queryCondition.ilike(modifiedSearchOnColumn, `%${serchText}%`)
    }
    const { data, error, count } = await queryCondition.order( sortOnColumn, {
                                ascending: (sortOrder === 'ascending')
                            })
                            .range( (page-1)*size, (page*size)-1 )
    if (error) {
        isError = true
    }
    
    let dataToReturn:any[] | null | undefined = data
    if (serchText && searchOnColumn) {
        switch (searchOnColumn) {
            case 'event_name':
                dataToReturn = data?.filter( (row) => {
                    if (row.events) {
                        return row
                    }
                    return false
                })
                break;

            case 'organization_name':
                dataToReturn = data?.filter( (row) => {
                    if (row.organizations) {
                        return row
                    }
                    return false
                })
                break;

            case 'template_name':
                dataToReturn = data?.filter( (row) => {
                    if (row.templates) {
                        return row
                    }
                    return false
                })
                break;
        
            default:
                dataToReturn = data
                break;
        }
    }

    return {
        isQueryError: isError,
        queryData: dataToReturn as jobDataTypeNested[] | null,
        queryError: error,
        querySize: count,
    }
}

export const updateJobName = async ({newName, jobId}:{newName:string, jobId:string}) => {
    let isError = false
    const { data, error } = await supabase.from('jobs')
                                        .update({
                                            name: newName
                                        }, {
                                            returning: 'minimal'
                                        })
                                        .eq('id', jobId)
    if (error) {
        isError = true
        displayError( 'Action failed with error', error.message || error.details || error.hint)
    }
    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error,
    }   
}

export const fetchJobDetails = async ( id:string, page:number=1, size:number=10, sortOnColumn:string='updated_at', sortOrder:string='ascending', serchText?:string|null, searchOnColumn?:string|null ) => {
    let isError = false
    let queryCondition = supabase
                            .from('certificates')
                            .select(`
                                id,
                                recipient_name,
                                recipient_email,
                                extra_metadata,
                                job_id,
                                certificate_status:certificate_status!certificate_status_certificate_id_fkey(
                                    certificate_id,
                                    is_opened,
                                    is_downloaded,
                                    is_verified,
                                    is_shared,
                                    is_delivered
                                ),
                                organizations(
                                    subdomain
                                ),
                                type,
                                is_published,
                                certificate_short_code,
                                created_at,
                                updated_at
                            `, {
                                count: "exact"
                            })
                            .eq( 'job_id', id )
                            .eq( 'is_deleted', false )

    if (serchText && searchOnColumn) {
        queryCondition = queryCondition.ilike(searchOnColumn, `%${serchText}%`)
    }

    const { data, error, count } = await queryCondition
                                            .order( sortOnColumn, {
                                                ascending: (sortOrder === 'ascending')
                                            })
                                            .range( (page-1)*size, (page*size)-1 )
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error,
        querySize: count,
    }                                       
}

export const addSubJob = async (subJobData:subJobType) => {
    let isError = false
    const { data, error } = await supabase.from('sub_jobs')
                                        .insert(subJobData, {
                                            returning: "minimal"
                                        })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as subJobType[] | null,
        queryError: error
    }
}

export const deleteJob = async ( values:deleteJobDataObjectType ) => {
    const dataToUpdate = {
        is_deleted: true
    }

    let isError = false
    const { data, error } = await supabase.from( 'jobs' ).update(dataToUpdate)
    .eq('id', values.id)
    .eq('user_id', values.user_id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as subJobType[] | null,
        queryError: error
    }
}