import { Box, Button, Checkbox, CheckboxGroup, Collapse, Divider, Editable, EditableInput, EditablePreview, Flex, Heading, HStack, IconButton, Input, Link, Radio, RadioGroup, Select, Skeleton, Spacer, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useState as useHookStateCore } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import moment from 'moment'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaEdit, FaPlus, FaRedo, FaTimes } from 'react-icons/fa'
import { HiPencilAlt } from 'react-icons/hi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { DrawerComponentWithRef } from '../../components/drawerComponent/DrawerComponent'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { ModalComponentWithRef } from '../../components/modalComponent/ModalComponent'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from '../../configs/GlobalConstants'
import { useAuth } from '../../contexts/Auth'
import { deleteJob, fetchJobs, updateJobName } from '../../dataFetchers/jobsDataFetcher'
import { paginationPageSizeArray } from '../../dataObjects/globalDataObject'
import { displayError, displaySuccess, recreateListWithDesiredOrder, stringOrNumber } from '../../helpers/CommonFunctions'
import { useHookState } from '../../hooks/useHookState'
import { loadingStatusStore } from '../../stores/LoadingStatusStore'
import { jobDataTypeNested } from '../../types/dataFetcherTypes/JobsDataFetcherTypes'

function useLocationQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Certificates = () => {
    const locationQuery = useLocationQuery()
    const history = useHistory()

    const [pageNo, setPageNo] = useState( parseInt(locationQuery.get('page') || DEFAULT_PAGE_NO.toString() ) )
    const [pageSize, setPageSize, rawPageSize] = useHookState( parseInt(locationQuery.get('size') || localStorage.getItem('global-page-size') || DEFAULT_PAGE_SIZE.toString() ) )  
    rawPageSize.attach(Persistence('global-page-size'))

    const [serchText, setSerchText] = useState<string|null>(null)
    const [searchOnColumn, setSearchOnColumn] = useState('event_name')
    const { isOpen, onToggle } = useDisclosure()
    const timeoutHolderForSearch = useRef<any>(null)
    const globalLoadingBar = useHookStateCore(loadingStatusStore)


    const availableColumns = [
        "id",
        "name",
        "event_id",
        "event_name",
        "organization_id",
        "organization_name",
        "template_id",
        "template_name",
        "certificate_type",
        "certificate_count",
        "created_at",
        "updated_at",
        "controls"
    ]

    const defaultDisplayColumns = [
        "id",
        "name",
        "event_name",
        "organization_name",
        "template_name",
        "updated_at",
        "controls",
    ]

    const availableColumnsForSearch = [
        "event_name",
        "organization_name",
        "template_name",
    ]

    const columnToDisplayValMapping:{ [key: string]: string } = {
        id: "Job Id",
        name: "Name",
        event_id: "Event Id",
        event_name: "Event Name",
        organization_id: "Organization Id",
        organization_name: "Organization Name",
        template_id: "Template Id",
        template_name: "Template Name",
        certificate_type: "Certificate Type",
        certificate_count: "Certificate Count",
        created_at: "Created On",
        updated_at: "Updated On",
        controls: "Controls",
    }

    const [columnsToDisplay, setColumnsToDisplay, rawColumnsToDisplay]  = useHookState<stringOrNumber[]>(defaultDisplayColumns)
    const [sortOnColumn, setSortOnColumn, rawSortOnColumn] = useHookState('updated_at')
    const [sortOrder, setSortOrder, rawSortOrder] = useHookState('descending')

    rawColumnsToDisplay.attach(Persistence('certificates-columns-to-display'))
    rawSortOnColumn.attach(Persistence('certificates-sort-on-column'))
    rawSortOrder.attach(Persistence('certificates-sort-order'))
    
    const queryClient = useQueryClient()
    const { user } = useAuth()
    const user_id = user?.id
    const { isError, isLoading, data, error, isFetching } = useQuery(['certificateData', pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn, user_id ], () => fetchJobs( pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn, user_id ), { keepPreviousData : true })

    const deleteMutation = useMutation(deleteJob, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('certificateData')
                displaySuccess('Job Deleted', 'Job is deleted')
            }
        }
    })

    useEffect( () => {
        const params = new URLSearchParams()
        if (pageNo) {
            params.append("page", pageNo.toString() )
        } else {
            params.delete("page")
        }
        if (pageSize) {
            params.append("size", pageSize.toString() )
        } else {
            params.delete("size")
        }
        if ( locationQuery.get('page') || (pageNo !== DEFAULT_PAGE_NO) || (pageSize !== DEFAULT_PAGE_SIZE) ) {
            history.replace({search: params.toString()})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pageNo, pageSize, history ])

    const jobNameUpdateMutation = useMutation(updateJobName, {
        onSuccess: data => {
            if (!data.isQueryError) {
                queryClient.invalidateQueries('certificateData')
                displaySuccess('Name Updated', 'Job name is updated successfully')
            }
        }
    })

    const handleJobNameChange = async (newName:string, jobId:string) => {
        globalLoadingBar.set({
            isLoading: true
        })
        await jobNameUpdateMutation.mutateAsync({
            newName,
            jobId
        })
        globalLoadingBar.set({
            isLoading: false
        })

    }

    const createDataForTable = (data:jobDataTypeNested[]) => {
        let returnObject = {
            keys: columnsToDisplay,
            header: columnToDisplayValMapping,
            body: [] as object[]
        }
        
        data.map( (item:jobDataTypeNested) => {
            returnObject.body.push({
                id: item.id ? <Link as={RouterLink} to={`/dashboard/certificates/status/${item.id}`}>
                        {item.id}
                    </Link> : 'null',
                name:   <Editable 
                            defaultValue={item.name ? item.name : 'null'}
                            onSubmit={(newVal) => handleJobNameChange(newVal, item.id)}
                        >
                            <EditablePreview />
                            <EditableInput />
                        </Editable>,
                event_id: item.events?.id || 'null',
                event_name: item.events?.name || 'null',
                organization_id: item.organizations?.organization_id || 'null',
                organization_name: item.organizations?.name || 'null',
                template_id: item.templates?.id || 'null',
                template_name: item.templates?.name || 'null',
                certificate_type: item.certificate_type,
                certificate_count: item.certificates.length,
                created_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.created_at).local().format('MMM DD, YYYY HH:mm') }
                        </Text>
                    </Stack>,
                updated_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.updated_at).local().format('MMM DD, YYYY HH:mm') }
                        </Text>
                    </Stack>,
                controls: <HStack>
                    <ModalComponentWithRef 
                        triggerComponent={
                            <IconButton
                                size="xs"
                                variant="outline"
                                colorScheme="red"
                                icon={<FaTimes />}
                                aria-label="Delete Organization"
                            />
                        }
                        heading="Delete"
                        showFooter={true}
                        footerButtons={
                            <Button 
                                colorScheme="blue"
                                onClick={ async () => {
                                    return await deleteMutation.mutateAsync({
                                        id: item.id!,
                                        user_id: user?.id!
                                    })
                                }}
                            >
                                Confirm
                            </Button>
                        }
                    >
                        {
                            <Text>
                                Please be informed that this action is irreversible, You won't be able to recover it once deleted. However, Don't worry as all the certificates issued in the past using this organization will retain it's value. 
                            </Text>
                        }
                    </ModalComponentWithRef>
                </HStack>
            })
            return true
        })

        return returnObject
    }

    const setDebouncedSerchText = (eve:ChangeEvent<HTMLInputElement>) => {
        const searchFieldValue = eve.currentTarget.value
        if (timeoutHolderForSearch.current !== null ) {
            clearTimeout(timeoutHolderForSearch.current)
        }
        timeoutHolderForSearch.current = setTimeout(() => {
            setSerchText(searchFieldValue)
        }, 300)
    }

    if ( data ) {
        const { queryData, queryError, querySize } = data

        if ( queryError ) {
            displayError( 'Action not allowed', queryError.message || queryError.details || queryError.hint)
        }

        if ( queryData ) {
            const lastPage = (Math.ceil(querySize!/pageSize) > 0) ? Math.ceil(querySize!/pageSize) : 1
            return (
                <>
                    <IconButton
                        size="lg"
                        fontSize="lg"
                        colorScheme="blue"
                        ml="auto"
                        onClick={() => {
                            history.push('/dashboard/certificates/new')
                        }}
                        borderRadius="50%"
                        m={2}
                        icon={<FaPlus />}
                        aria-label="Create new Job"
                        position="fixed"
                        right={{
                            base: 2,
                            md: 10
                        }}
                    />
                    <Box h={8} />
                    <Card>
                        <Flex
                            borderBottomWidth={1}
                            direction='column'
                            alignItems='flex-start'
                        >
                            <Flex alignItems="center" w='100%' mb={4}>
                                <Heading as="h2" fontSize="lg">
                                    Jobs
                                </Heading>
                                <Spacer />
                                <Button
                                    leftIcon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['certificateData', pageNo, pageSize, sortOnColumn, sortOrder])
                                    }}
                                    display={["none", "inherit", null, null]}
                                    isLoading={isFetching}
                                >
                                    Refresh
                                </Button>
                                <IconButton 
                                    aria-label="Refresh Jobs Data"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['certificateData', pageNo, pageSize, sortOnColumn, sortOrder])
                                    }}
                                    icon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    display={["inherit", "none", null, null]}
                                    isLoading={isFetching}
                                />
                                <Button
                                    aria-label='Open Advance Options'
                                    rightIcon={isOpen ? <FaAngleUp /> : <FaAngleDown /> }
                                    leftIcon={<FaEdit />}
                                    onClick={onToggle}
                                    variant='outline'
                                >
                                    Advance
                                </Button>
                            </Flex>
                            <Collapse 
                                style={{
                                    width: '100%',
                                }} 
                                in={isOpen} 
                                animateOpacity
                            >
                                <Flex w='100%' mb={4} direction={['column', null, 'row', null]} >
                                    <Flex alignItems='center'>
                                        <Text pr='2'>
                                            Filter:
                                        </Text>
                                        <Input
                                            type='search'
                                            mr={3}
                                            placeholder={columnToDisplayValMapping[searchOnColumn]}
                                            onChange={setDebouncedSerchText}
                                        />
                                        <Select
                                            defaultValue={searchOnColumn}
                                            onChange={(val) => {
                                                setSearchOnColumn(val.target.value)
                                            }}
                                        >
                                            {
                                                availableColumnsForSearch.map((columnKey) => (
                                                    <option key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </option>
                                                ))
                                            }
                                        </Select>
                                    </Flex>
                                    <Spacer />
                                    <Flex pt={['2', null, '0', null]}>
                                        <Spacer />
                                        <DrawerComponentWithRef
                                            heading="Table Properties"
                                            triggerComponent={
                                                <Button variant="outline" minW="20" leftIcon={<HiPencilAlt />} >
                                                    Edit
                                                </Button>
                                            }
                                            showFooter={true}
                                        >
                                            <CardWithColorAccent>
                                                <Heading size="sm" mb="4">
                                                    Columns To display
                                                </Heading>
                                                <Divider mb="6" />
                                                <CheckboxGroup 
                                                    colorScheme="blue"
                                                    defaultValue={columnsToDisplay}
                                                    onChange={ newVal => setColumnsToDisplay(recreateListWithDesiredOrder(availableColumns, newVal)) }
                                                >
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumns.map((columnKey) => (
                                                                <Checkbox key={columnKey} value={columnKey}>{ columnToDisplayValMapping[columnKey] }</Checkbox>
                                                            ))
                                                        }
                                                    </VStack>
                                                </CheckboxGroup>
                                            </CardWithColorAccent> 
                    
                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort by
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOnColumn} value={sortOnColumn}>
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumns.filter( i => !['controls', 'organization_name', 'event_name', 'template_name'].includes(i) ).map((columnKey) => (
                                                                <Radio key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </Radio>
                                                            ))
                                                        }
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent> 
                    
                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort Order
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOrder} value={sortOrder}>
                                                    <VStack alignItems="flex-start">
                                                        <Radio value='ascending'> Ascending </Radio>
                                                        <Radio value='descending'> Descending </Radio>
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent>                              
                                        </DrawerComponentWithRef>
                                    </Flex>
                                </Flex>
                            </Collapse>
                        </Flex>
                        <ResponsiveTable data={createDataForTable(queryData)} />
                        <Flex alignItems="center" mt={3} borderTopWidth={1} pt={4} >
                            <Text>
                                {`Showing ${((pageNo-1)*pageSize)+1} - ${ ( querySize! < pageNo*pageSize ) ? querySize : pageNo*pageSize } of ${querySize}`}
                            </Text>
                            <Spacer />
                            <IconButton
                                aria-label="First Page"
                                icon={ <FaAngleDoubleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    setPageNo(1)
                                }}
                            />
                            <IconButton
                                aria-label="Previous Page"
                                icon={ <FaAngleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    if ( pageNo > 1 ) {
                                        setPageNo( p => p - 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Next Page"
                                icon={ <FaAngleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    if ( pageNo < lastPage ) {
                                        setPageNo( p => p + 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Last Page"
                                icon={ <FaAngleDoubleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    setPageNo( lastPage )
                                }}
                            />
                            <Select 
                                w="auto" 
                                defaultValue={pageSize} 
                                onChange={(val) => {
                                    const changedPageSize = parseInt( val.target.value )
                                    setPageSize(changedPageSize)
                                    if ( changedPageSize*pageNo > querySize! ) {
                                        const lastPage = (Math.ceil(querySize!/changedPageSize) > 0) ? Math.ceil(querySize!/changedPageSize) : 1
                                        setPageNo( lastPage )
                                    }
                                }}
                            >
                                {
                                    paginationPageSizeArray.map( size => (
                                        <option key={size} value={size}> { size } </option>
                                    ))
                                }
                            </Select>
                        </Flex>
                    </Card>
                </>
            )
        }
    }
    
    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    if ( !data ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    return (
        <ErrorComponent />
    )
}

